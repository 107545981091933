import React, { useEffect, useMemo, useState } from 'react';

import Table from 'components/UI/TableConfig/Table';
import { columnTypes } from 'components/UI/TableConfig/column-helper';
import {
  DispatchProps,
  OwnProps,
  StateProps,
  TableOwnProps,
} from 'components/UI/TableConfig/types';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { getColumnAlignmentByType } from 'utils/aligns';

type IProps = OwnProps &
  Omit<TableOwnProps, 'columns'> &
  StateProps &
  DispatchProps;

export const styleFirstVisibleColumn = (columns: IColumn[], width?: number) => {
  if (columns.length) {
    const firstShowingColumnIndex = columns.findIndex(
      (column) => !column.hidden
    );

    columns[firstShowingColumnIndex].config.className = 'primary-cell';
    columns[firstShowingColumnIndex].width = width || 250;
    columns[firstShowingColumnIndex].maxWidth = width || 250;
  }
};

/**
 * @deprecated This component is deprecated in favor of a more streamlined and efficient approach.
 * Use `useGetSettingsForTable` to fetch table configurations and `useManageTableConfigurationColumns`
 * to apply column type functions, if needed. This new method reduces reliance on multiple `useEffect`
 * hooks for fetching table configurations and applying column type logic. It leverages `useQuery`
 * for enhanced data fetching capabilities, allowing for the conditional use of column parsing logic.
 * For practical implementation examples, refer to `RevBiDealsModals`.
 *
 * Reason: This change aims to simplify data management by minimizing the dependency on various `useEffect`
 * hooks, which in turn enhances component efficiency and maintainability. It offers a more organized and
 * scalable approach to handling table settings and column configurations.
 */
const TableConfig: React.FC<IProps> = ({
  companyCurrency,
  isMulticurrencyEnabled,
  getTableColumnConfiguration,
  onReady = () => {},
  onSpecialClick = () => {},
  sequence,
  sfBaseUrl,
  tableColumnConfiguration,
  specialTableConfig: {
    riskMoreButtonLabel,
    showSyncDealButton,
    getButtonLabel,
    valueAsNotAvailableFor = [],
  } = {},
  customTableColumnConfiguration,
  styleFirstColumn = true,
  onCloseModal,
  oppSplitViewToggle,
  ...tableOwnProps
}: IProps) => {
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [isTableConfigInitialized, setTableConfigInitialized] = useState(false);
  const tableConfiguration =
    customTableColumnConfiguration || tableColumnConfiguration;

  useEffect(() => {
    if (!customTableColumnConfiguration) {
      getTableColumnConfiguration();
    }
  }, []);

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrency,
        0,
        isMulticurrencyEnabled
      ),
      riskMoreButtonLabel,
      showSyncDealButton,
      getButtonLabel,
      sequence,
    },
    onSpecialClick,
    sfBaseUrl,
    isMulticurrencyEnabled
  );

  useEffect(() => {
    const newColumns = tableConfiguration.columns
      .map<IColumn>((column, index) => ({
        field: column.object_field,
        crm_field: column.crm_field,
        editable: column.editable,
        sort_order: SortOrder.DESCENDING,
        sortable: column.sortable,
        id: `${column.field_name}-${index}`,
        align: getColumnAlignmentByType(
          column.type == 'custom' ? column.meta?.type : column.type
        ),
        advance: column.advance ?? false,
        label: column.display_name,
        hidden:
          tableOwnProps.hiddenColumns && tableOwnProps.hiddenColumns.length
            ? tableOwnProps.hiddenColumns.includes(column.object_field)
            : column.hidden,
        useValueAsNotAvailable: valueAsNotAvailableFor.includes(
          column.field_name
        ),
        fieldHeaderHighlight: !!column.fieldHeaderHighlight,
        ...getColumnType(column, tableConfiguration.columns),
      }))
      .map((column) => ({
        ...column,
        showTooltip: ![
          ColumnTypes.NUMBER,
          ColumnTypes.PERCENT,
          ColumnTypes.MONEY,
        ].includes(column.type),
      }));

    if (styleFirstColumn) {
      styleFirstVisibleColumn(newColumns);
    }

    if (tableConfiguration.columns.length) {
      setTableConfigInitialized(true);
    }

    if (tableOwnProps.isModal) {
      setColumns(
        newColumns.map((column) => ({
          ...column,
          showTooltip: ![
            ColumnTypes.NUMBER,
            ColumnTypes.PERCENT,
            ColumnTypes.MONEY,
            ColumnTypes.DATE,
          ].includes(column.type),
        }))
      );
    } else {
      setColumns(newColumns);
    }
  }, [JSON.stringify(tableConfiguration.columns)]);

  const orderedColumns = useMemo(() => {
    if (
      tableOwnProps.showOrHideColumns &&
      tableOwnProps.showOrHideColumns.length
    ) {
      const allColumns = [...columns];
      // create orderedColumns base on the ordering of showOrHideColumns
      const newOrderedColumns = tableOwnProps.showOrHideColumns.reduce(
        (pre: IColumn[], showOrHideCol) => {
          const idx = allColumns.findIndex(
            (c) => c.label === showOrHideCol.label
          );
          if (idx !== -1) {
            pre = pre.concat(allColumns.splice(idx, 1));
          }
          return pre;
        },
        []
      );
      // in case new column is added, there might be left over items in allColumns
      // those column will be added to the end
      return newOrderedColumns.concat(allColumns);
    } else {
      // no order specified. do nothing
      return columns;
    }
  }, [tableOwnProps.showOrHideColumns, columns]);

  // Apply dynamic logic based on showOppSplitToggle to columns
  const finalColumns = useMemo(
    () =>
      orderedColumns.map((column) => ({
        ...column,
        editable:
          column.config.isMoney && tableOwnProps.showOppSplitToggle
            ? !oppSplitViewToggle
            : column.editable,
      })),
    [oppSplitViewToggle, orderedColumns]
  );

  useEffect(() => {
    if (isTableConfigInitialized) {
      onReady(tableConfiguration);
    }
  }, [isTableConfigInitialized]);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Table
      columns={finalColumns}
      onCloseModal={onCloseModal}
      {...tableOwnProps}
    />
  );
};

export default TableConfig;
